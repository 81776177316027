import * as React from "react"
import { Link } from 'gatsby'
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Reviews from "../components/Reviews"
import { services } from "../data/services"
import { StaticImage } from "gatsby-plugin-image"
import lonelyGirlImage from "../images/girl-gf80db41ac_1280.jpg"

const Counseling = () => (
    <Layout>
        <Seo title="Counseling" />
        <h1>Astrological Counseling</h1>
        <section /*style={{ position: "relative", zIndex: "-1" }}*/>
            {/* <img src={lonelyGirlImage} className="mb-4 mt-2 sm:mt-0 sm:float-right sm:w-1/2 sm:ml-2"></img> */}
            {/* <StaticImage
                src="../images/girl-gf80db41ac_1280.jpg"
                width={500}
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="Lonely girl sitting by window"
                className="mb-4 mt-2 sm:mt-0 sm:float-right sm:w-1/2 sm:ml-2"
            /> */}

            <p>Why am I here? What am I supposed to do with my life? Why am I not happy? Why do the same problems keep manifesting in my life over and over again?</p>
            <p>This is but a few examples of the types of questions people may want to bring to an evolutionary astrologer.</p>
            <p>I cannot answer these questions for you - but I might be able to help you find the answers you already carry inside of you.</p>

        </section>
        <p>Some people would rather ask a more material question - Should I quit my job? Should I buy the house? - and that is fine too. Just be aware that I will not use astrological predictions to tell you what to do. All I can do is to use astrology as a mirror of what is going on in your life. Through that mirror, you may find the external validation you need to proceed with important life changes.</p>
        <p>As I am still relatively new in the field, my price is low compared to more established astrologers.</p>
        <h2 className="mt-12 border-b border-gray-900 mb-4">Reviews</h2>
        <Reviews />
        <h2>Astrology as Fast Track Therapy</h2>
        <p>I have no formal therapeutic training. Nevertheless, <span className="bold">I've had clients who experienced one counseling session with me as more helpful than several sessions of therapy.</span> This is because your birth chart can help me to understand the fundamental themes in your life even before our meeting has begun.</p>
        <p>Book a consultation with me via PayPal below and I will get back to you as soon as possible.</p>
        <p>If you want to inquire about a consultation but aren't ready to book yet, <Link to="/contact">send me a message</Link> or keep reading below for general information about consultations.</p>
        <br></br>
        <h2 id="how-does-it-work">How Does It Work?</h2>
        <p>Most of my consultations are done via video (usually Zoom). When you choose to do a video consultation, you will get a recording of the entire session sent to you free of charge. I also meet some clients in person, but then you have to live near Uppsala, Sweden.</p>
        <p>Some clients want the consultation to be heavy on astrological analysis. Others don't really know or care about the astrology but just want to have some basic perspective on their life in plain language. Whatever you prefer, we will adapt the consultation to suit your needs.</p>
        <p>I always prepare your birth chart before our consultation. When you meet me, I will have an interpretation of your chart ready, but I will always be prepared to modify that interpretation based on what you are telling me about your life and your current struggles.</p>
        <p>I'm not the kind of astrologer who will give you a monologue about your Sun sign or Moon sign. I will not tell you what your Mercury-Mars opposition means before I understand who you are and where you're coming from. Instead, I try to enter our meeting from a place of sensitivity and curiosity, and I will often ask you to give me details about the things you are struggling with. Sometimes I will ask about your childhood, upbringing, relationship with your parents etc. At some point during our meeting, <span className="bold">the chart begins to come alive,</span> the story and underlying theme of your life becomes clear which allows me to give you concrete advice about your way forward.</p>
        <p>Together with the consultation, you can also order a written birth chart report based on the findings of our meeting. This is recommended for people who want to focus heavily on the astrological details of their chart but don't want to lose time writing everything down during the consultation. I will then write a summary afterwards and send it to you.</p>
        <p><span className="bold">Remember, I'm going to need your birth data - date, time, and place - to accurately cast your horoscope.</span> If you don't know the time of day within 15 minutes or so, my reading of your chart may be less accurate.</p>
        <br></br>
        <h2>Services</h2>
        <hr></hr>
        {services.map(service => (
            <section className="mt-12">
                <section className="flex justify-between">
                    <h3>{service.name}</h3>
                    <h3>${service.price}</h3>
                </section>
                <hr></hr>
                <p>{service.description}</p>
                <form className="flex justify-center mb-12" action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                    <input type="hidden" name="cmd" value="_xclick" />
                    <input type="hidden" name="business" value="kim.johan.lindgren@gmail.com" />
                    <input type="hidden" name="lc" value="US" />
                    <input type="hidden" name="item_name" value={service.name} />
                    <input type="hidden" name="amount" value={service.price + ".00"} />
                    <input type="hidden" name="currency_code" value="USD" />
                    <input type="hidden" name="button_subtype" value="services" />
                    <input type="hidden" name="no_note" value="0" />
                    <input type="hidden" name="bn" value="PP-BuyNowBF:btn_buynowCC_LG.gif:NonHostedGuest" />
                    <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!" />
                    <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                </form>
            </section>
        ))}



        {/* <form action="https://www.paypal.com/donate" method="post" target="_top">
            <input type="hidden" name="hosted_button_id" value="7S59MTTLJB6AL" />
            <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
            <img alt="" border="0" src="https://www.paypal.com/en_SE/i/scr/pixel.gif" width="1" height="1" />
        </form> */}

    </Layout>
)

export default Counseling
